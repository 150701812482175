<script setup lang="ts">
import { TranslationKey } from '~/i18n/TranslationKeys'
import { TextSizes } from '~~/src/constants/textSizes'
import { UtilSales } from '~~/src/utils/UtilSales'

const isSalesMode = UtilSales.isSalesMode
const displayComponent = computed(() => UtilSales.canUseSalesMode() && isSalesMode.value)
</script>

<template>
	<div
		v-if="displayComponent"
		class="shadow-high-contrast sticky bottom-3 left-3 ml-3 flex w-fit items-center gap-2 rounded-3xl bg-sky-100 px-4 py-2"
	>
		<div class="h-2 w-2">
			<div class="absolute h-2 w-2 rounded-full bg-sky-600" />
			<div class="absolute h-2 w-2 animate-ping rounded-full bg-sky-600" />
		</div>
		<CommonText :text="'Demo mode' as TranslationKey" :text-size="TextSizes.PRODUCTIVE" class="text-sky-900" />
	</div>
</template>
