<script setup lang="ts">
const emits = defineEmits(['close', 'makeAnotherWish'])
</script>

<template>
	<div class="h-full">
		<div class="bg-image bg-make-wish-image h-full w-full rounded-[20px]">
			<!-- Header -->
			<div class="p-4 pb-2">
				<SpButton
					:size="SpButtonSize.DefaultIconOnly"
					:type="SpButtonType.Tertiary"
					:icon-name="SpCommonIcon.Times"
					class="ml-auto"
					@click="emits('close')"
				></SpButton>
			</div>

			<!-- Content -->
			<div class="w-full p-10">
				<!-- Sent wish message -->
				<div class="flex w-full flex-col gap-8" style="background-image: url('@/assets/images/onboarding_banner.png')">
					<MakeWishSvgsMail />

					<div class="flex w-full flex-col items-start gap-10">
						<div class="flex flex-col gap-3">
							<SpText
								:size="SpTextSize.ProductiveLabel02"
								class="text-sky-400"
								:text="$t(TranslationKeys['make_a_wish_dialog.wish_sent.SUBTITLE'])"
							></SpText>
							<SpText
								:size="SpTextSize.HeadingLg01"
								class="max-w-[250px] text-sky-50"
								:text="$t(TranslationKeys['make_a_wish_dialog.wish_sent.TITLE'])"
							></SpText>

							<SpText
								:size="SpTextSize.BodyMd02"
								class="max-w-[512px] text-sky-50"
								:text="$t(TranslationKeys['make_a_wish_dialog.wish_sent.DESCRIPTION'])"
							></SpText>
						</div>

						<SpButton
							:size="SpButtonSize.Default"
							:type="SpButtonType.Tertiary"
							:text="$t(TranslationKeys['make_a_wish_dialog.wish_sent.SEND_ANOTHER_WISH_BUTTON'])"
							@click="emits('makeAnotherWish')"
						></SpButton>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
